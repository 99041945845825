<template>
    <div class="itemOnPrintSet">
        <div class="left-table table-box">
            <el-select v-model="showType" default-first-option>
                <el-option :value="0" label="按菜品类别"></el-option>
                <el-option :value="1" label="按出单位置"></el-option>
            </el-select>
            <div class="scroll-box">
                <el-tree
                    ref="treeRef"
                    class="filter-tree"
                    :data="showType==0?treeData:kitTreeData"
                    :expand-on-click-node="false"
                    highlight-current
                    default-expand-all
                    @current-change="currentChange"
                />
            </div>
        </div>
        <div class="right-table ">
            <div class="filter-box">
                <div class="from-box">
                    <div class="lable-txt">出单类型:</div>
                    <div class="from-input" style="width: 180px;">
                        <el-select v-model="KitIssuings" placeholder="全部" multiple collapse-tags>
                            <el-option v-for="type in KitIssuingType" :key="type" :value="type.Eat_IssueAutoID" :label="type.Eat_IssueName"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="from-box">
                    <el-checkbox v-model="isKitIssueType" label="仅显示未设置出单类型的菜品" ></el-checkbox>
                </div>
                <div class="from-box">
                    <el-checkbox v-model="isNotShowTc" label="不展现套餐头菜品" ></el-checkbox>
                </div>
            </div>
            <div class="table-box grey-table">
                <table-vir class="el-table--scrollable-y" style="width: 100%;height:100%" 
                    ref="elTable"
                    :data="pageTableData"
                    highlight-current-row
                    @selection-change="selectFood"
                    :row-class-name="tableRowClassName"
                    :cell-class-name="$parent.setClassName"
                    @cell-dblclick="$parent.dblclick"
                >
                    <table-column-vir fixed class-name="is-fixed" label="选择" type="selection" width="40" ></table-column-vir>
                    <table-column-vir fixed class-name="is-fixed" prop="Food_Code" label="菜品编码" min-width="100" align="left">
                        <template #default="scope">
                            <span v-copyText>{{scope.row.Food_Code}}</span>
                        </template>
                    </table-column-vir>
                    <table-column-vir fixed class-name="is-fixed" prop="Food_Name" label="菜品名称" min-width="100" align="left">
                        <template #default="scope">
                            <span v-copyText>{{scope.row.Food_Name}}</span>
                        </template>
                    </table-column-vir>
                    <table-column-vir fixed class-name="is-fixed" prop="Food_Price" label="售价" min-width="80" >
                        <template #default="scope">
                            {{scope.row.Food_Price}}/{{scope.row.Food_Size}}
                        </template>
                    </table-column-vir>
                    <table-column-vir label="出单类型选择">
                        <table-column-vir min-width="80" v-for="type in KitIssuingRows" :key="type">
                            <template #header>
                                <el-dropdown trigger="click" :max-height="408">
                                    <span class="el-dropdown-link" >
                                    {{type.Eat_IssueName}}<span class="kit_batch" >[批量]</span>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="batch(type,true)">是</el-dropdown-item>
                                            <el-dropdown-item @click="batch(type,false)">否</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </template>
                            <template #default="scope">
                                <el-checkbox v-model="editFoodKitTypes[scope.row.Food_ID][type.Eat_IssueAutoID]" @click="rowSelect(scope.row)"></el-checkbox>
                            </template>
                        </table-column-vir>
                    </table-column-vir>
                </table-vir>
            </div>
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                        isBlurClose
                ></key-board-input>
                <div class="float-right">
                    <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                    <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                    <div class="page-bnt">
                        <el-select v-model="pageSize"  >
                            <el-option :value="20" label="20条/页"></el-option>
                            <el-option :value="30" label="30条/页"></el-option>
                            <el-option :value="50" label="50条/页"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**菜品关联打印类型设置 */
export default {
    name:"itemOnPrintSet",
    emits:["hideNumber"],
    props:{
        /**所有菜品 */
        PosFoods:{
            typeof:Array,
            default:null
        },
        /**菜品分类 */
        foodMainCategory:{
            typeof:Array,
            default:null
        },
        /**出单位置 */
        KitIssuePlace:{
            typeof:Array,
            default:null
        },
        /**出单类型 */
        KitIssuingType:{
            typeof:Array,
            default:null
        },
        /**需要隐藏的菜品id */
        printHideXfIDList:{
            typeof:Array,
            default:null
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        label:(sub.SubCategory_Code+"-"+sub.SubCategory_Name).replace(/^[-]/,""),
                        SubCategory_ID:sub.SubCategory_ID,
                        //PosFoods:sub.PosFoods
                    })
                });
                tree.push({
                    id:category.MainCategory_ID,
                    label:(category.MainCategory_Code+"-"+category.MainCategory_Name).replace(/^[-]/,""),
                    children:children
                });
            })
            return [{label:"全部",children:tree,id:0}];
        },
        kitTreeData(){
            let tree=[];
            this.KitIssuePlace?.forEach(it=>{
                tree.push({
                    id:it.IssuePlace_AutoID,
                    label:it.IssuePlace_Code+"-"+it.IssuePlace_Name,
                });
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            if(this.showType==0){//按分类
                if(!this.currentNode){
                    return this.PosFoods;
                }
                else if(this.currentNode?.children){
                    let foods=[];
                    let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                    if(category){
                        category.FoodSubCategorys?.forEach(f=>{
                            foods=foods.concat(f.PosFoods);
                        });
                    }
                    return foods;
                }
                return this.PosFoods.filter(t=>t.SubCategory_ID==this.currentNode.id);
            }else{//按出单位置
                if(!this.currentNode){
                    return this.PosFoods;
                }else{
                    return this.PosFoods.filter(t=>t.KitPlace_ID==this.currentNode.id);
                }
            }
        },
        /**过滤要隐藏的菜品 */
        tableShowFilter(){
            let data=this.tableData||[];
            return data.filter(it=>this.printHideXfIDList.indexOf(it.Food_ID)<0);
        },
        /**筛选数据 */
        tableFilter(){
            let data=this.tableShowFilter||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.isNotShowTc){
                data=data.filter(it=>!it.SetMealInfo);
            }
            if(this.isKitIssueType){//仅显示未设置出单类型的菜品
                data=data.filter(it=>{
                    let types=[];
                    this.KitIssuingType.forEach(type=>{//菜品关联出单类型  出单类型默认的出单位置
                        if(type.Place_AutoIds.indexOf(it.KitPlace_ID)>=0){
                            types.push({
                                KitIssueType_ID:type.Eat_IssueAutoID,
                                Chosen_YN:true,
                            })
                        }
                    })
                    this.foodKitIssueType?.forEach(type=>{//菜品关联出单类型数据
                        if(type.Food_ID==it.Food_ID){
                            type.KitIssueTypesChoose?.forEach(choose=>{
                                let t= types.find(t=>t.KitIssueType_ID==choose.KitIssueType_ID);
                                if(t){
                                    t.Chosen_YN=choose.Chosen_YN;
                                }else{
                                    types.push({
                                        KitIssueType_ID:choose.KitIssueType_ID,
                                        Chosen_YN:choose.Chosen_YN,
                                    })
                                }
                            })
                        }
                    })
                    if(!types?.some(it=>it.Chosen_YN)){
                        return true;
                    }
                    return false;
                })
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.tableFilter.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.tableFilter.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        },
        /**出单类型列 */
        KitIssuingRows(){
            if(this.KitIssuings?.length>0){

                return  this.KitIssuingType?.filter(it=>this.KitIssuings.indexOf(it.Eat_IssueAutoID)>=0) ;
            }
            return this.KitIssuingType;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("itemOnPrintSet_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        KitIssuingRows(){
            this.setFoodKitTyps();
        },
        PosFoods(){
            this.setFoodKitTyps();
        }
    },
    data(){
        let pageSize= this.$cacheData.get("itemOnPrintSet_pageSize")||20;
        return {
            /**菜品关联出单类型 */
            foodKitIssueType:[],
            /**0按菜品类别  1按出单位置*/
            showType:0,
            tablePageIndex:0,
            pageSize:pageSize,
            //选中的节点
            currentNode:null,
            //搜索数据
            searchTxt:"",
            //仅显示未设置出单类型的菜品
            isKitIssueType:false,
            /*勾选 菜品关联出单类型数据*/
            editFoodKitTypes:{},
            /**勾选要修改的菜品 */
            checkFoods:[],
            /**出单类型 */
            KitIssuings:null,
            /**是否不显示套餐菜品 */
            isNotShowTc:false,
        }
    },
    updated(){
        this.$nextTick(()=>{
            let header=this.$refs.elTable?.$el?.querySelector(".el-table__header");
            if(header && this.isVisible(header)){
                let width=0;
                let table=this.$refs.elTable?.$el;
                Array.from(header.children[0].children).forEach((it,i)=>{
                    if(i<3){
                        width+=it.clientWidth;
                        table.style["--fiexd"+(i+1)]=width+"px";
                    }
                })
            }
            
        })
    },
    mounted(){
        this.$nextTick(()=>{
            this.setFoodKitTyps();
        })
    },
    methods:{
        isVisible(elem){
            return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**设置类名 */
        tableRowClassName({ row }){
            let num=0;
            return this.KitIssuingRows?.some(it=>{
                if(this.editFoodKitTypes[row.Food_ID][it.Eat_IssueAutoID]){
                    num++;
                }
                return num>1;
            })?"edit":""
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        /**修改时 勾选行 */
        rowSelect(row){
            this.$refs.elTable?.toggleRowSelection(row,true);
        },
        /**批量勾选 */
        batch(type,isBo){
            let isSelect=this.checkFoods?.length>0;
            this.pageTableData?.forEach(food=>{
                if(isSelect){
                    if(this.checkFoods.some(it=>it==food)){
                        this.editFoodKitTypes[food.Food_ID][type.Eat_IssueAutoID]=isBo;
                    }
                }else{
                    this.editFoodKitTypes[food.Food_ID][type.Eat_IssueAutoID]=isBo;
                    this.rowSelect(food);
                }
            })
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        setFoodKitTyps(){
            this.$refs.elTable?.clearSelection();//清空勾选
            let foodKit={};
            this.PosFoods?.forEach(it=>{
                foodKit[it.Food_ID]={};
                if(it.KitPlace_ID){
                    this.KitIssuingType?.forEach(type=>{//菜品关联出单类型  出单类型默认的出单位置
                        if(type.Place_AutoIds.indexOf(it.KitPlace_ID)>=0){
                            foodKit[it.Food_ID][type.Eat_IssueAutoID]=true;
                        }else{
                            foodKit[it.Food_ID][type.Eat_IssueAutoID]=false;
                        }
                    })
                }
            })

            this.foodKitIssueType?.forEach(it=>{//菜品关联出单类型数据
                if(foodKit[it.Food_ID]){
                    it.KitIssueTypesChoose?.forEach(type=>{
                        foodKit[it.Food_ID][type.KitIssueType_ID]=type.Chosen_YN;
                    })
                }
            })
            this.editFoodKitTypes=foodKit;
        },
        loadData(isRefresh){
            if(!isRefresh && this.foodKitIssueType?.length>0){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.GetFoodKitIssueType",{
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.foodKitIssueType=d.ResponseBody;
                    //this.printHideXfIDList=d.ResponseBody?.PrintHideXfIDList;//要隐藏的菜品
                    this.setFoodKitTyps();
                }
            }).catch(e=>{
                loading.close();
                console.log('e:',e)
                this.$alert('获取菜品关联出单类型数据失败', "提示", {confirmButtonText: "确定"});
            })
        },
        /**保存 菜品出单位置数据 */
        save(){
            if(!this.checkFoods?.length>0){
                this.$message.warning('请先勾选要保存的数据');
                return;
            }

            let foodsKit=[];


            this.checkFoods?.forEach(food=>{
                let types=[];
                Object.keys(this.editFoodKitTypes[food.Food_ID]).forEach(key=>{
                    types.push({
                        KitIssueType_ID:key,
                        Chosen_YN:this.editFoodKitTypes[food.Food_ID][key]
                    })
                })

                foodsKit.push({
                    Food_ID:food.Food_ID,
                    KitIssueTypesChoose:types
                })

                if(food.MultipleSizeFoods?.length>0){//多规格
                    food.MultipleSizeFoods.forEach(it=>{
                        if(it.Food_ID!=food.Food_ID){
                            foodsKit.push({
                                Food_ID:it.Food_ID,
                                KitIssueTypesChoose:types
                            });
                        }
                    })
                }
            })
            
            if(foodsKit.length==0){
                this.$message.warning('数据无修改！请先修改在进行保存');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.SetFoodKitIssueType",{
                FoodsLinkKitIssueTypes:foodsKit,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    this.$refs.elTable?.clearSelection();
                    if(this.foodKitIssueType?.length>0){
                        foodsKit?.forEach(food=>{
                            let oldFood=this.foodKitIssueType.find(f=>f.Food_ID==food.Food_ID);
                            if(oldFood){
                                oldFood.KitIssueTypesChoose=food.KitIssueTypesChoose;
                            }else{
                                this.foodKitIssueType.push(food);
                            }
                        })
                    }else{
                        this.foodKitIssueType=foodsKit;
                    }
                    
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        }
    }
}
</script>

<style>

</style>